import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Menu, Item, Separator } from "react-contexify";
import "react-contexify/ReactContexify.css";
import { withRouter } from "react-router-dom";
import axios from "axios";
import { toast } from "react-toastify";
import { getUrl } from "../../../getBackend";

import { changeStatus, getInvoices } from "./actions";
import { confirmAlert } from "react-confirm-alert";

const Context = (props) => {
    const onClickPending = ({ props: invoiceId }) => {
        props.changeStatus([invoiceId], "pending");
    };

    const onClickAwaitingPayment = ({ props: invoiceId }) => {
        props.changeStatus([invoiceId], "awaiting_payment");
    };

    const onClickPaid = ({ props: invoiceId }) => {
        props.changeStatus([invoiceId], "paid");
    };

    const onClickInvoice = ({ props: invoiceId }) => {
        downloadFile(
            `${getUrl()}/api/v1/invoices/${invoiceId}/download_invoice/`
        );
    };

    const onClickTimesheet = ({ props: invoiceId }) => {
        downloadFile(
            `${getUrl()}/api/v1/invoices/${invoiceId}/download_timesheet/`
        );
    };

    const onClickCsv = ({ props: invoiceId }) => {
        downloadFile(
            `${getUrl()}/api/v1/invoices/${invoiceId}/download_csv_timesheet/`
        );
    };

    const downloadFile = (url) => {
        axios(url)
            .then((res) => {
                window.open(
                    `${getUrl()}/api/download_file/${res.data.id}/`,
                    "_blank"
                );
            })
            .catch((error) => {
                console.error(error);
                toast("Unable to generate download.", { type: "error" });
            });
    };

    const sendInvoiceToCustomer = ({ props: invoiceId }) => {
        confirmAlert({
            title: "Are you sure you want to send the invoice?",
            buttons: [
                {
                    label: "No",
                },
                {
                    label: "Yes",
                    onClick: () =>
                        axios
                            .post(
                                `${getUrl()}/api/v1/invoices/${invoiceId}/send/`
                            )
                            .then((res) => {
                                toast("Invoice send succesfully!", {
                                    type: "success",
                                });

                                props.getInvoices();
                            })
                            .catch((error) => {
                                console.error(error);
                                toast("Unable to send invoice.", {
                                    type: "error",
                                });
                            }),
                },
            ],
        });
    };

    const sendInvoiceReminderToCustomer = ({ props: invoiceId }) => {
        confirmAlert({
            title: "Are you sure you want to send the reminder for the invoice?",
            buttons: [
                {
                    label: "No",
                },
                {
                    label: "Yes",
                    onClick: () =>
                        axios
                            .post(
                                `${getUrl()}/api/v1/invoices/${invoiceId}/send_reminder/`
                            )
                            .then((res) => {
                                toast("Invoice reminder send succesfully!", {
                                    type: "success",
                                });

                                props.getInvoices();
                            })
                            .catch((error) => {
                                console.error(error);
                                toast("Unable to send invoice.", {
                                    type: "error",
                                });
                            }),
                },
            ],
        });
    };

    return (
        <Menu id="menu_id">
            <Item onClick={onClickPending}>Change status to pending</Item>
            <Item onClick={onClickAwaitingPayment}>
                Change status to awaiting payment
            </Item>
            <Item onClick={onClickPaid}>Change status to paid</Item>
            <Separator />
            <Item onClick={sendInvoiceToCustomer}>
                Send invoice to customer
            </Item>
            <Item onClick={sendInvoiceReminderToCustomer}>
                Send reminder to customer
            </Item>
            <Separator />
            <Item onClick={onClickInvoice}>Download invoice</Item>
            <Item onClick={onClickTimesheet}>Download timesheet</Item>
            <Item onClick={onClickCsv}>Download CSV</Item>
        </Menu>
    );
};

Context.propTypes = {
    changeStatus: PropTypes.func.isRequired,
    getInvoices: PropTypes.func.isRequired,
};

const mapStateToProps = () => ({});

const mapDispatchToProps = { changeStatus, getInvoices };

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(Context));
