export const REGISTER_RESET_INPUT = "REGISTER_RESET_INPUT";

export const resetInputsFunc = () => ({
    type: REGISTER_RESET_INPUT,
});

export function resetInputs() {
    // Reset local state of these inputs
    setTimeout(() => {
        document.getElementById("start").value = "";
        document.getElementById("end").value = "";
        document.getElementById("break").value = "";
    }, 1);

    return (dispatch) => {
        dispatch(resetInputsFunc());
    };
}
